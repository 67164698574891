import { useFlag } from '@unleash/proxy-client-react';
import { observer } from 'mobx-react';
import type * as React from 'react';
import { NetworkState } from 'stores/NetworkState';
import { logo, logoIcon, logoText, spinner } from './Logo.css';

const LogoComponent: React.VFC = () => {
  const rebrandingEnabled = useFlag('rebranding');

  const loading = NetworkState.read_or_save_in_progress;

  return (
    <a href="/home" id="logo" tabIndex={1} className={logo}>
      <span className={logoIcon({ loading, rebrand: rebrandingEnabled })} />
      {loading && <span className={spinner} />}
      <span className={logoText({ rebrand: rebrandingEnabled })} />
    </a>
  );
};

export const Logo = observer(LogoComponent);

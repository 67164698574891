import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'clsx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { CurrentUser } from 'stores/CurrentUser';
import { I18n } from 'util/translations';
import { Logo } from './Logo';

const { pathname } = window.location;

function navArray(auditLogEnabled: boolean): readonly {
  href: string;
  label: string;
  id: string;
  active: boolean;
  subnav?: React.ReactElement;
}[] {
  if (
    CurrentUser.is_limited_access_user ||
    CurrentUser.is_consultant ||
    CurrentUser.is_countrymanager ||
    CurrentUser.is_reference_manager ||
    CurrentUser.is_internationalmanager
  ) {
    let dashboardUrl = '/dashboard#/persons/proposal/false';

    if (CurrentUser.id && CurrentUser.default_cv_id) {
      dashboardUrl += `/cv/${CurrentUser.id}/${CurrentUser.default_cv_id}`;
    }

    const array = [
      {
        href: dashboardUrl,
        label: I18n.t('My_CV'),
        id: 'my_cv_tab',
        active: pathname.startsWith('/edit-cv'),
      },
      {
        href: '/dashboard',
        label: I18n.t('Dashboard'),
        id: 'dashboard_tab',
        active: pathname === '/dashboard',
      },
    ];

    if (CurrentUser.is_internationalmanager || CurrentUser.is_countrymanager) {
      return [
        ...array,
        {
          href: '/settings',
          label: I18n.t('Account'),
          id: 'settings_tab',
          active: pathname === '/tools' || pathname === '/settings',
          subnav: (
            <div id="settings_list">
              <ul id="settings_links">
                <li>
                  <a href="/settings#/templates">
                    {I18n.t('settings_labels.templates_heading')}
                  </a>
                  <a href="/settings#/users">{I18n.t('Users')}</a>
                  {CurrentUser.is_internationalmanager && (
                    <>
                      <a href="/settings#/customize_interface">
                        {I18n.t('settings_labels.customize_interface')}
                      </a>
                      <a href="/settings#/compliance">
                        {I18n.t('settings_labels.compliance')}
                      </a>
                      <a href="/settings#/data_import">
                        {I18n.t('settings_labels.data_import.data_import')}
                      </a>
                      <a href="/tools#/masterdata">
                        {I18n.t('settings_labels.masterdata')}
                      </a>
                    </>
                  )}
                  <a href="/settings#/apikeys">
                    {I18n.t('settings_labels.api_keys')}
                  </a>
                  {auditLogEnabled && CurrentUser.is_internationalmanager && (
                    <a href="/settings#/audit_log">
                      {I18n.t('settings_labels.audit_log')}
                    </a>
                  )}
                  <a href="/settings#/help_guides">
                    {I18n.t('settings_labels.guides_and_learning')}
                  </a>
                </li>
              </ul>
            </div>
          ),
        },
      ];
    }

    return array;
  }

  return [
    {
      href: '/edit-cv/default',
      label: I18n.t('My_CV'),
      id: 'my_cv_tab',
      active: pathname.startsWith('/edit-cv'),
    },
  ];
}

const PrimaryNavComponent: React.VFC = () => {
  const auditLogEnabled = useFlag('enterprise-feature-audit-log');

  return (
    <nav className="primary_nav">
      {navArray(auditLogEnabled).map((navHash, index) => (
        <React.Fragment key={navHash.id}>
          <a
            className={classNames('tab', { active: navHash.active })}
            href={navHash.href}
            tabIndex={5 + index * 2}
            id={navHash.id}
          >
            <span className="nav_icon" />
            <span className="navigationlabel">{navHash.label}</span>
          </a>
          {navHash.subnav}
        </React.Fragment>
      ))}
    </nav>
  );
};

const PrimaryNav = observer(PrimaryNavComponent);

const LinksComponent: React.VFC<{ isLoggedIn: boolean }> = ({ isLoggedIn }) => {
  if (!isLoggedIn) {
    return (
      <div id="topNav">
        <a href="/login" tabIndex={50}>
          {I18n.t('Log_in')}
        </a>
      </div>
    );
  }

  return (
    <>
      <div id="topNav">
        {CurrentUser.is_authenticated_by_password ? (
          <a
            className="current_user"
            tabIndex={48}
            href="/change_password"
            title={CurrentUser.name}
          >
            {CurrentUser.name}
          </a>
        ) : (
          <span className="current_user" title={CurrentUser.name}>
            {CurrentUser.name}
          </span>
        )}
        <a href="/logout" tabIndex={49}>
          {I18n.t('Log_out')}
        </a>
      </div>
      <PrimaryNav />
    </>
  );
};

const Links = observer(LinksComponent);

const HeaderComponent: React.VFC<{ isLoggedIn: boolean }> = ({
  isLoggedIn,
}) => {
  React.useEffect(() => {
    if (isLoggedIn) {
      CurrentUser.reload_if_not_loaded();
    }
  }, [isLoggedIn]);

  return (
    <div className="innerHeader">
      <Logo />
      {isLoggedIn && !CurrentUser.is_loaded ? null : (
        <Links isLoggedIn={isLoggedIn} />
      )}
    </div>
  );
};

export const Header = observer(HeaderComponent);

import * as React from "react";
import { Timeout as ViewsSharedTimeout } from "auto-converted/views/shared/Timeout";
import { http } from "util/fetch";

export const TimeoutWatcher: React.VFC = () => {
  const [showingYouWillBeLoggedOut, setShowingYouWillBeLoggedOut] =
    React.useState(false);

  React.useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const loggedInStatus = await http.get<any>("/api/v2/logged_in_status");
        const sessionExpiresInMinutes =
          loggedInStatus.session_expires_in_minutes;
        if (!sessionExpiresInMinutes || sessionExpiresInMinutes <= 10) {
          setShowingYouWillBeLoggedOut(true);
        }
      } catch {
        // ignore - but should we report to Sentry instead?
      }
    }, 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const continueCallback = React.useCallback(async (postponedExpiryTime) => {
    try {
      await http.post("/api/v2/logged_in_status", {
        body: {
          logged_in_status: { postponed_expiry_time: postponedExpiryTime },
        },
      });

      setShowingYouWillBeLoggedOut(false);
    } catch {
      // ignore - but maybe report to Sentry?
    }
  }, []);

  if (!showingYouWillBeLoggedOut) {
    return null;
  }

  return (
    <div id="timeout_modals">
      <ViewsSharedTimeout continue_callback={continueCallback} />
    </div>
  );
};
